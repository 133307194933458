/* Contact.css */
.contact {
  padding: 50px 0;
  text-align: center;
  background: white;
}

.contact h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.contact form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 400px;
  margin: 0 auto;
}

.contact input,
.contact textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  height: 40px; /* Set the same height for inputs and textarea */
  resize: vertical; /* Allow vertical resizing for textarea */
}

.contact textarea {
  height: 120px; /* Set a larger height for the textarea */
}

.contact button {
  background: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
}

.contact button:hover {
  background: #45a049;
}
