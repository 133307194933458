/* Footer.css */
footer {
  background: #333;
  color: white;
  text-align: center;
  padding: 20px 0;
  margin-top: 50px;
}

footer p {
  margin: 0;
  font-size: 1rem;
}
