/* About.css */
.about {
  background: #f4f4f4;
  padding: 50px 0;
  text-align: center;
}

.about h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.about p {
  font-size: 1.1rem;
  color: #555;
  max-width: 800px;
  margin: 0 auto;
}
