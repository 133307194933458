/* Hero.css */
.hero {
  background: url("../../../public/images/placeholder.jpg") no-repeat center
    center/cover;
  color: white;
  padding: 100px 0;
  text-align: center;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.hero .btn {
  background: #ffc107;
  color: black;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
}
