/* Products.css */
.products {
  padding: 50px 0;
  text-align: center;
  background: white;
}

.product-grid {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.product-card {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.product-card:hover {
  transform: translateY(-10px);
}

.product-card img {
  width: 100%;
  border-radius: 10px;
}

.product-card h3 {
  margin: 15px 0 10px;
  font-size: 1.5rem;
}

.product-card p {
  font-size: 1rem;
  color: #666;
}

.product-card .btn {
  background: #4caf50;
  color: white;
  padding: 8px 16px;
  text-decoration: none;
  border-radius: 5px;
  display: inline-block;
  margin-top: 10px;
}
