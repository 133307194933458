/* Header.css */
header {
  background: #403e3e;
  color: white;
  padding: 0px 0;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333; /* Color de fondo */
  color: white; /* Color del texto */
  width: 100%; /* Asegúrate de que ocupe todo el ancho */
  transition: top 0.3s; /* Para una transición suave */
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.logo {
  height: 50px;
}

.nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 20px;
}

.nav ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

/* Estilos para el menú sandwich */
.hamburger {
  display: none; /* Oculto por defecto en pantallas grandes */
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
}

.hamburger .line {
  width: 25px;
  height: 3px;
  background: white;
}

/* Estilos para dispositivos móviles */
@media (max-width: 768px) {
  .hamburger {
    display: flex; /* Mostrar el ícono en móviles */
  }

  .nav {
    position: absolute;
    top: 60px; /* Ajusta según la altura del header */
    right: 0;
    background: #4caf50;
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
  }

  .nav.open {
    height: 200px; /* Altura del menú desplegado */
  }

  .nav ul {
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
  }
}
