/* App.css */
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  background: linear-gradient(135deg, #f4f4f4, #e0e0e0);
  color: #333;
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.btn {
  background: #4caf50;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  display: inline-block;
  margin-top: 10px;
  font-weight: bold;
  transition: background 0.3s ease;
}

.btn:hover {
  background: #45a049;
}
