/* WhatsAppButton.css */
.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.whatsapp-button a {
  display: block;
  background-color: #25d366; /* Color de WhatsApp */
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.whatsapp-button a:hover {
  background-color: #128c7e; /* Color de WhatsApp al hacer hover */
}

.whatsapp-button img {
  width: 40px; /* Tamaño del ícono */
  height: 40px;
}
