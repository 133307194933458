/* Duratek.css */

.duratek-container {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: flex-start; /* Alinea al principio del contenedor en el eje vertical */
  height: 100vh; /* 100% de la altura de la ventana */
}

.duratek-content {
  width: 60%; /* Ajusta el ancho del div, puedes cambiarlo según lo desees */
  padding: 20px;
  border: 1px solid black;
  background-color: #f0f0f0;
}

/* Estilo único y campestre para el h1 */
.duratek-container h1 {
  font-family: "Georgia", serif; /* Fuente con estilo campestre */
  font-size: 2.5em; /* Tamaño de fuente grande */
  color: #4a7c2c; /* Color verde campestre */
  margin-bottom: 20px; /* Espacio debajo del h1 */
}

/* Estilo único y campestre para el p */
.duratek-container p {
  font-family: "Arial", sans-serif; /* Fuente más simple */
  font-size: 1.2em; /* Tamaño de fuente moderado */
  color: #5a5a5a; /* Color gris oscuro */
  line-height: 1.6; /* Espaciado entre líneas */
  margin: 0 auto; /* Centra el párrafo */
  max-width: 600px; /* Ancho máximo para el párrafo */
}

.product-page {
  text-align: center;
  width: 600px;
}

.image-card {
  width: 300px;
}
